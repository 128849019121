// src/components/Contact.jsx
import React, { useState } from 'react';
import { Mail, Instagram, MessageSquare } from 'lucide-react';

const Contact = () => {
  const [feedback, setFeedback] = useState('');
  const [status, setStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Load environment variables
  const FEEDBACK_FORM_ACTION_URL = import.meta.env.VITE_FEEDBACK_FORM_ACTION_URL;
  const FEEDBACK_ENTRY_ID = import.meta.env.VITE_FEEDBACK_ENTRY_ID;

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append(FEEDBACK_ENTRY_ID, feedback);

      await fetch(FEEDBACK_FORM_ACTION_URL, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString()
      });

      // Show success message
      setFeedback('');
      setStatus('Thank you for your feedback! We really appreciate your input! 🎉');
      
    } catch (error) {
      console.error('Error:', error);
      setStatus('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
      setTimeout(() => setStatus(''), 5000);
    }
  };

  return (
    <section id="contact" className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-center">Contact Us</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {/* Left Side - Contact Information */}
          <div className="flex flex-col justify-start space-y-8 md:pr-8">
            <div className="bg-gray-50 p-8 rounded-lg shadow-sm h-full">
              <h3 className="text-2xl font-semibold mb-8">Get in Touch</h3>
              
              <div className="space-y-6">
                <div className="flex items-center space-x-4 group">
                  <div className="bg-[#FF6B35] bg-opacity-10 p-3 rounded-full group-hover:bg-opacity-20 transition-all duration-300">
                    <Mail className="w-6 h-6 text-[#FF6B35]" />
                  </div>
                  <a 
                    href="mailto:Ezra@travel-squad.com" 
                    className="text-lg hover:text-[#FF6B35] transition-colors"
                  >
                    Ezra@travel-squad.com
                  </a>
                </div>
                
                <div className="flex items-center space-x-4 group">
                  <div className="bg-[#FF6B35] bg-opacity-10 p-3 rounded-full group-hover:bg-opacity-20 transition-all duration-300">
                    <Instagram className="w-6 h-6 text-[#FF6B35]" />
                  </div>
                  <a 
                    href="https://www.instagram.com/travel_squad_adventures/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-lg hover:text-[#FF6B35] transition-colors"
                  >
                    @travel_squad_adventures
                  </a>
                </div>
              </div>
              
              <div className="mt-12 p-6 bg-[#FF6B35] bg-opacity-5 rounded-lg">
                <p className="text-gray-600">
                  Follow us on social media for travel tips, destination highlights, and community updates!
                </p>
              </div>
            </div>
          </div>

          {/* Right Side - Feedback Form */}
          <div className="bg-gray-50 p-8 rounded-lg shadow-sm">
            <div className="flex items-center gap-3 mb-6">
              <MessageSquare className="w-6 h-6 text-[#FF6B35]" />
              <h3 className="text-2xl font-semibold">Help Us Improve! 🚀</h3>
            </div>
            
            <p className="text-gray-600 mb-6">
              As a startup, we value your input immensely! Whether you've traveled with us or not, 
              we'd love to hear your thoughts on how we can make your travel experience better.
              Sign you name and best way to contact so we can reach out. 
            </p>
            
            <form onSubmit={handleFeedbackSubmit} className="space-y-4">
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="What would make your travel experience better? Any features you'd like to see? Your feedback helps us create a better platform for everyone!"
                className="w-full p-4 border border-gray-200 rounded-lg focus:outline-none focus:border-[#FF6B35] min-h-[200px]"
                required
                disabled={isSubmitting}
              />
              <button 
                type="submit"
                className="w-full bg-[#FF6B35] text-white px-6 py-3 rounded-full hover:bg-[#FF8C35] transition duration-300 flex items-center justify-center gap-2 disabled:opacity-50 disabled:hover:bg-[#FF6B35]"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Submitting...
                  </span>
                ) : (
                  <>
                    <span>Share Your Thoughts</span>
                    <MessageSquare size={20} />
                  </>
                )}
              </button>
            </form>
            
            {status && (
              <div className="mt-4 animate-fade-in">
                <p className="text-green-600 text-center bg-green-50 p-3 rounded-lg">
                  {status}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;