// src/components/sections/trips/PurchasePage.jsx
import React, { useState, useCallback } from 'react';
import CountrySelector from './CountrySelector';

const PurchasePage = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleCountrySelect = useCallback((country) => {
    setSelectedCountry(country);
  }, []);

  return (
    <div id="trips" className="container mx-auto px-4 py-16">
      <CountrySelector onSelect={handleCountrySelect} />
    </div>
  );
};

export default PurchasePage;