import React, { useState } from 'react';
import { ChevronDown, Check, Clock } from 'lucide-react';
import Flag from 'react-world-flags';
import CityModal from './CityModal';
import { cityData } from '../../../data/cities';


const CountrySelector = ({ onSelect }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const getReadyCitiesCount = (countryCode) => {
    const country = cityData[countryCode];
    if (!country) return 0;
    return country.cities.filter(city => city.ready).length;
  };

  // Sort countries: those with ready cities first, then alphabetically
  const sortedCountries = Object.keys(cityData).sort((a, b) => {
    const aReadyCount = getReadyCitiesCount(a);
    const bReadyCount = getReadyCitiesCount(b);
    
    if (aReadyCount === bReadyCount) {
      return cityData[a].name.localeCompare(cityData[b].name);
    }
    return bReadyCount - aReadyCount;
  });

  const handleCountrySelect = (countryCode) => {
    console.log('Country Data:', cityData[countryCode]); // Debug log
    const country = {
      code: countryCode,
      name: cityData[countryCode].name
    };
    setSelectedCountry(country);
    if (onSelect) onSelect(country);
  };

  return (
    <div className="mb-12">
      <h2 className="text-3xl font-bold mb-4 text-center text-[#2F3437]">Choose Your Travel Itinerary</h2>
      <h3 className="text-2xl font-semibold mb-8 text-center text-[#2F3437]">Choose Your Destination</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {sortedCountries.map((countryCode) => {
          const readyCitiesCount = getReadyCitiesCount(countryCode);
          
          return (
    <button
        key={countryCode}
        className="w-full flex items-center justify-between p-4 bg-white border rounded-lg transition duration-300 hover:bg-gray-50"
        onClick={() => handleCountrySelect(countryCode)}
      >
        <div className="flex items-center flex-1">
          <Flag code={countryCode} className="w-8 h-6 mr-3" />
          <span className="text-sm font-medium text-[#2F3437]">
            {cityData[countryCode].name}
          </span>
        </div>
        {readyCitiesCount > 0 ? (
          <span className="inline-flex items-center text-green-500 text-sm whitespace-nowrap">
            <Check className="w-4 h-4 mr-1" />
            1 City Ready
          </span>
        ) : (
          <span className="inline-flex items-center text-[#FF6B35] text-sm whitespace-nowrap">
            <Clock className="w-4 h-4 mr-1" />
            Coming Soon
          </span>
        )}
    </button>
          );
        })}
      </div>

      {selectedCountry && (
        <CityModal
          country={selectedCountry}
          onClose={() => setSelectedCountry(null)}
        />
      )}
    </div>
  );
};
export default CountrySelector;