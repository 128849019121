export const cityData = {
    CZ: {
      name: "Czech Republic",
      code: "CZ",
      cities: [
        {
          name: "Prague",
          ready: true,
          image: "/images/cities/Prague.jpg",
          description: "A picturesqe city of bridges, cathedrals, gold-tipped towers and church spires. Prague has been mirrored in the surface of the Vltava River for more than a millennium.",
          stripeLink: "https://buy.stripe.com/3cs5om5lR09H68o001",
          travelInfo: {
            timeOffset: "NYC +6 hours",
            duration: "3-4 days",
            language: "Very tourist-friendly, English widely spoken in tourist areas",
            transport: "Excellent public transit system (metro, trams, buses), very walkable historic center",
            currency: "Czech Koruna (CZK)",
            bestTime: "March to May or September to October",
            travelTips: [
              "Purchase a 3-day public transport pass (310 CZK)",
              "Book Prague Castle tickets online to avoid queues",
              "Most museums are closed on Mondays",
              "Tipping 10% is customary in restaurants",
              "Avoid tourist trap restaurants near Old Town Square",
              "Always have some cash (CZK) on hand",
              "Visit major attractions early morning or late afternoon",
              "Watch out for pickpockets in tourist areas"
            ]
          }
        },
        {
            name: "Brno",
            ready: false,
            image: "",
            description: "The historic capital of Moravia, known for its modernist architecture, vibrant cultural scene, and authentic Czech atmosphere.",
            stripeLink: "YOUR_BRNO_STRIPE_LINK",
            travelInfo: {
              timeOffset: "NYC +6 hours",
              duration: "2-3 days",
              language: "English less common than Prague, but still widely understood",
              transport: "Efficient tram and bus network, walkable city center",
              currency: "Czech Koruna (CZK)",
              bestTime: "April to October",
              travelTips: [
                "Get a 24-hour public transport ticket",
                "Visit the Vegetable Market for local products",
                "Many restaurants offer cheaper lunch menus",
                "Download the DPMB public transport app",
                "Carry cash for smaller establishments",
                "Most shops close early on Saturdays",
                "Sunday shopping is limited",
                "Book accommodation near the city center"
              ]
            }
          },
        {
          name: "Český Krumlov",
          ready: false,
          image: "",
          description: "A picturesque medieval town with a stunning castle complex and winding streets, recognized as a UNESCO World Heritage site.",
          stripeLink: "",
          travelInfo: {
            timeOffset: "NYC +6 hours",
            duration: "1-2 days",
            language: "English commonly spoken in tourist areas",
            transport: "Walkable town center, no public transport needed",
            currency: "Czech Koruna (CZK)",
            bestTime: "April to October",
            travelTips: [
              "Visit the castle early in the morning",
              "Consider a day trip from Prague",
              "Book accommodation in advance during summer",
              "Take a rafting trip on the Vltava River",
              "Visit the Baroque theater",
              "Try local Czech cuisine",
              "Explore the monastery gardens",
              "Take advantage of photography spots at sunrise"
            ]
          }
        }
      ],
      comboPackage: {
        ready: false,
        price: 35,
        stripeLink: "",
        description: "Experience the best of the Czech Republic with our comprehensive guide covering both historic Prague and medieval Český Krumlov."
      }
    },


    AT: {
        name: "Austria",
        code: "AT",
        cities: [
          {
            name: "Vienna",
            ready: false,
            image: "",
            description: "An imperial capital of music, art, and elegant architecture, where classical history meets contemporary culture.",
            stripeLink: "YOUR_VIENNA_STRIPE_LINK",
            travelInfo: {
              timeOffset: "NYC +6 hours",
              duration: "3-4 days",
              language: "English widely spoken in tourist areas",
              transport: "Extensive metro, tram and bus network",
              currency: "Euro (€)",
              bestTime: "April to October or December for Christmas markets",
              travelTips: [
                "Buy a Vienna Pass for major attractions",
                "Get a weekly transport pass",
                "Many museums close on Mondays",
                "Book opera tickets well in advance",
                "Restaurants typically add service charge",
                "Tap water is excellent quality",
                "Shops close early on Saturdays",
                "Standing tickets available for opera houses"
              ]
            }
          },
          {
            name: "Salzburg",
            ready: false,
            image: "",
            description: "The birthplace of Mozart and setting of The Sound of Music, known for its baroque architecture and alpine surroundings.",
            stripeLink: "YOUR_SALZBURG_STRIPE_LINK",
            travelInfo: {
              timeOffset: "NYC +6 hours",
              duration: "2-3 days",
              language: "English commonly spoken in tourist areas",
              transport: "Efficient bus system, walkable old town",
              currency: "Euro (€)",
              bestTime: "May to September or December for Christmas markets",
              travelTips: [
                "Purchase the Salzburg Card for attractions",
                "Use O-Bus for public transport",
                "Book Sound of Music tours in advance",
                "Many shops closed on Sundays",
                "Carry cash for smaller establishments",
                "Reserve restaurants in advance",
                "Watch weather forecasts for mountain visits",
                "Get combination tickets for multiple museums"
              ]
            }
          },
          {
            name: "Innsbruck",
            ready: false,
            image: "",
            description: "A picturesque city surrounded by Alps, combining imperial heritage with outdoor adventure.",
            stripeLink: "YOUR_INNSBRUCK_STRIPE_LINK",
            travelInfo: {
              timeOffset: "NYC +6 hours",
              duration: "2-3 days",
              language: "English widely spoken in tourist areas",
              transport: "Efficient tram and bus system, cable cars to mountains",
              currency: "Euro (€)",
              bestTime: "December to March for skiing, June to September for hiking",
              travelTips: [
                "Get the Innsbruck Card for attractions",
                "Check weather before mountain trips",
                "Book ski passes online in advance",
                "Use the Sightseer bus for attractions",
                "Carry proper gear for mountain activities",
                "Restaurant kitchens often close early",
                "Many shops closed on Sundays",
                "Reserve mountain huts in advance during season"
              ]
            }
          }
        ],
        comboPackage: {
          ready: false,
          price: 35,
          stripeLink: "",
          description: "Discover the magic of Austria through its imperial capital Vienna, musical Salzburg, and alpine Innsbruck."
        }
      },
    
  CA: {
    name: "Canada",
    code: "CA",
    cities: [
      {
        name: "Toronto",
        ready: false,
        image: "",
        description: "Canada's largest city, a multicultural metropolis known for its iconic CN Tower, diverse neighborhoods, and vibrant arts scene.",
        stripeLink: "YOUR_TORONTO_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +0 hours",
          duration: "3-4 days",
          language: "English primary language",
          transport: "Extensive public transit (TTC), subway, streetcars, and buses",
          currency: "Canadian Dollar (CAD)",
          bestTime: "June to September",
          travelTips: [
            "Get a Presto card for public transport",
            "Purchase CityPASS for attractions",
            "Tipping 15-20% is standard",
            "Book CN Tower visits for sunset",
            "Consider day trips to Niagara Falls",
            "Underground PATH system useful in winter",
            "Carry layers for variable weather",
            "Book popular restaurants in advance"
          ]
        }
      },
      {
        name: "Vancouver",
        ready: false,
        image: "",
        description: "A coastal city surrounded by mountains, known for its outdoor activities, film industry, and high quality of life.",
        stripeLink: "YOUR_VANCOUVER_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC -3 hours",
          duration: "3-4 days",
          language: "English primary language",
          transport: "SkyTrain, buses, and seabus system",
          currency: "Canadian Dollar (CAD)",
          bestTime: "June to September",
          travelTips: [
            "Get a Compass Card for transit",
            "Book Capilano Bridge tickets online",
            "Carry rain gear even in summer",
            "Use SeaBus to visit North Vancouver",
            "Reserve popular restaurants ahead",
            "Check cruise ship schedules",
            "Bike rentals available for Stanley Park",
            "Use Canada Line from airport"
          ]
        }
      },
      {
        name: "Montreal",
        ready: false,
        image: "",
        description: "A blend of European charm and North American energy, famous for its cultural scenes, historic architecture, and food.",
        stripeLink: "YOUR_MONTREAL_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +0 hours",
          duration: "3-4 days",
          language: "French and English widely spoken",
          transport: "Metro system and buses",
          currency: "Canadian Dollar (CAD)",
          bestTime: "June to September, July for festivals",
          travelTips: [
            "Get an OPUS card for metro",
            "Learn basic French greetings",
            "Use BIXI bikes in summer",
            "Check festival schedules",
            "Many places closed on Mondays",
            "Explore underground city in winter",
            "Restaurant tips are 15-20%",
            "Book popular restaurants ahead"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Canada's diversity through its vibrant cities: Toronto's multiculturalism, Vancouver's natural beauty, and Montreal's European charm."
    }
  },


  CO: {
    name: "Colombia",
    code: "CO",
    cities: [
      {
        name: "Bogota",
        ready: false,
        image: "",
        description: "Colombia's high-altitude capital, mixing colonial charm with modern urban culture and rich museums.",
        stripeLink: "YOUR_BOGOTA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC -0 hours",
          duration: "3-4 days",
          language: "Spanish primary, limited English in tourist areas",
          transport: "TransMilenio bus system, taxis",
          currency: "Colombian Peso (COP)",
          bestTime: "December to March",
          travelTips: [
            "Adjust to altitude gradually",
            "Use official taxi services",
            "Get a TuLlave card for TransMilenio",
            "Carry cash for small purchases",
            "Sunday Ciclovía is car-free",
            "Be cautious at night",
            "Book Monserrate visits early",
            "Learn basic Spanish phrases"
          ]
        }
      },
      {
        name: "Cartagena",
        ready: false,
        image: "",
        description: "A UNESCO World Heritage site with colorful colonial architecture, Caribbean beaches, and vibrant culture.",
        stripeLink: "YOUR_CARTAGENA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC -0 hours",
          duration: "3-4 days",
          language: "Spanish primary, some English in tourist areas",
          transport: "Walking in old town, taxis for longer distances",
          currency: "Colombian Peso (COP)",
          bestTime: "December to April",
          travelTips: [
            "Stay hydrated in humid climate",
            "Negotiate taxi fares upfront",
            "Book island tours early",
            "Carry small bills for tips",
            "Visit beaches in early morning",
            "Use sunscreen year-round",
            "Book hotels in walled city",
            "Avoid beach vendors politely"
          ]
        }
      },
      {
        name: "Medellin",
        ready: false,
        image: "",
        description: "The City of Eternal Spring, known for its innovation, art scene, and perfect climate.",
        stripeLink: "YOUR_MEDELLIN_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC -0 hours",
          duration: "3-4 days",
          language: "Spanish primary, growing English in tourist areas",
          transport: "Metro and cable car system",
          currency: "Colombian Peso (COP)",
          bestTime: "December to March",
          travelTips: [
            "Use Metro for safe transport",
            "Get MetroCard for cable cars",
            "Book free walking tours",
            "Visit markets in morning",
            "Use official taxi apps",
            "Carry cash for local shops",
            "Learn basic Spanish",
            "Stay in El Poblado area"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Discover Colombia's diversity through its captivating cities: Bogota's altitude, Cartagena's colonial charm, and Medellin's eternal spring."
    }
  },

  // Croatia
  HR: {
    name: "Croatia",
    code: "HR",
    cities: [
      {
        name: "Dubrovnik",
        ready: false,
        image: "",
        description: "The Pearl of the Adriatic, famous for its ancient city walls, limestone streets, and stunning coastal location.",
        stripeLink: "YOUR_DUBROVNIK_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "Croatian, English widely spoken in tourist areas",
          transport: "Walking in old town, buses for longer distances",
          currency: "Euro (€)",
          bestTime: "May-June or September-October",
          travelTips: [
            "Buy city walls tickets early morning",
            "Get Dubrovnik Card for attractions",
            "Book cable car in advance",
            "Avoid cruise ship crowds",
            "Carry water and sun protection",
            "Use water taxi to nearby islands",
            "Book accommodation early",
            "Visit beaches in late afternoon"
          ]
        }
      },
      {
        name: "Split",
        ready: false,
        image: "",
        description: "A stunning coastal city built around Diocletian's Palace, blending ancient history with modern life.",
        stripeLink: "YOUR_SPLIT_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "Croatian, English common in tourist areas",
          transport: "Walking in old town, buses and ferries",
          currency: "Euro (€)",
          bestTime: "May-June or September-October",
          travelTips: [
            "Book ferry tickets in advance",
            "Visit markets in morning",
            "Get Split Card for discounts",
            "Use official taxi apps",
            "Carry cash for small shops",
            "Book island tours early",
            "Reserve restaurants ahead",
            "Check ferry schedules online"
          ]
        }
      },
      {
        name: "Zagreb",
        ready: false,
        image: "",
        description: "Croatia's capital combining Austro-Hungarian architecture with Mediterranean spirit.",
        stripeLink: "YOUR_ZAGREB_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "Croatian, English widely spoken",
          transport: "Trams and buses, walkable center",
          currency: "Euro (€)",
          bestTime: "April-September",
          travelTips: [
            "Get Zagreb Card for transport",
            "Visit Dolac market early",
            "Use tram for longer distances",
            "Many museums free on first Wednesday",
            "Coffee culture important",
            "Summer festivals common",
            "Book guided tours ahead",
            "Carry small change for trams"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Croatia's diversity from Dubrovnik's medieval charm to Split's coastal beauty and Zagreb's continental sophistication."
    }
  },
  //Cuba
  CU: {
    name: "Cuba",
    code: "CU",
    cities: [
      {
        name: "Havana",
        ready: false,
        image: "",
        description: "Vibrant capital known for vintage cars, colonial architecture, and rich cultural heritage.",
        stripeLink: "YOUR_HAVANA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +0 hours",
          duration: "3-4 days",
          language: "Spanish, limited English",
          transport: "Classic taxis, cocotaxis, walking",
          currency: "Cuban Peso (CUP) and Cuban Convertible Peso (CUC)",
          bestTime: "November to March",
          travelTips: [
            "Carry cash always",
            "Book casas particulares",
            "Download offline maps",
            "Negotiate taxi fares",
            "Learn basic Spanish",
            "Book restaurants ahead",
            "Early morning walks",
            "Get tourist card visa"
          ]
        }
      },
      {
        name: "Trinidad",
        ready: false,
        image: "",
        description: "UNESCO World Heritage site known for colonial streets, music scenes, and nearby beaches.",
        stripeLink: "YOUR_TRINIDAD_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +0 hours",
          duration: "2-3 days",
          language: "Spanish, very limited English",
          transport: "Walking, horse carts, taxis",
          currency: "Cuban Peso (CUP) and Cuban Convertible Peso (CUC)",
          bestTime: "December to April",
          travelTips: [
            "Book casa particular",
            "Carry small bills",
            "Download offline maps",
            "Bring water bottle",
            "Wear comfortable shoes",
            "Evening music venues",
            "Book day trips early",
            "Check beach transport"
          ]
        }
      },
      {
        name: "Varadero",
        ready: false,
        image: "",
        description: "Premier beach resort town with pristine beaches and water activities.",
        stripeLink: "YOUR_VARADERO_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +0 hours",
          duration: "3-4 days",
          language: "Spanish, some English in resorts",
          transport: "Hotel shuttles, taxis, hop-on-hop-off bus",
          currency: "Cuban Peso (CUP) and Cuban Convertible Peso (CUC)",
          bestTime: "November to April",
          travelTips: [
            "Book resorts advance",
            "Exchange money safely",
            "Reserve activities early",
            "Carry beach essentials",
            "Check tour schedules",
            "Book airport transfers",
            "Use resort wifi",
            "Carry cash backup"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Cuba's magic from Havana's historic charm to Trinidad's colonial beauty and Varadero's beach paradise."
    }
  },

 // Cyprus
 CY: {
    name: "Cyprus",
    code: "CY",
    cities: [
      {
        name: "Nicosia",
        ready: false,
        image: "",
        description: "The world's last divided capital, offering a unique blend of Greek and Turkish cultures within its historic Venetian walls.",
        stripeLink: "YOUR_NICOSIA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "2-3 days",
          language: "Greek and Turkish, English widely spoken",
          transport: "Buses and taxis, walkable old city",
          currency: "Euro (€)",
          bestTime: "March to May or September to November",
          travelTips: [
            "Carry passport for crossing green line",
            "Use official border crossing points",
            "Most shops close early afternoon",
            "Sunday shopping is limited",
            "Carry water in summer months",
            "Book guided walks in advance",
            "Use official taxi services",
            "Exchange money at banks only"
          ]
        }
      },
      {
        name: "Limassol",
        ready: false,
        image: "",
        description: "Cyprus's cosmopolitan coastal city, known for its beaches, historic castle, and wine production.",
        stripeLink: "YOUR_LIMASSOL_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "3-4 days",
          language: "Greek, English widely spoken",
          transport: "Buses and taxis, promenade walkable",
          currency: "Euro (€)",
          bestTime: "April to October",
          travelTips: [
            "Use bus card for public transport",
            "Book beach beds in advance",
            "Carry beach shoes for pebbles",
            "Restaurants typically open late",
            "Siesta observed 2-4pm",
            "Book wine tours ahead",
            "Haggle at local markets",
            "Tipping 10% standard"
          ]
        }
      },
      {
        name: "Paphos",
        ready: false,
        image: "",
        description: "A UNESCO World Heritage city combining ancient ruins with modern beach resort amenities.",
        stripeLink: "YOUR_PAPHOS_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "3-4 days",
          language: "Greek, English widely spoken",
          transport: "Buses and taxis",
          currency: "Euro (€)",
          bestTime: "April to November",
          travelTips: [
            "Get Paphos Pass for attractions",
            "Visit archaeological sites early",
            "Book harbor restaurants ahead",
            "Use sunscreen year-round",
            "Carry cash for small shops",
            "Book diving trips advance",
            "Rent car for remote beaches",
            "Evening harbor walks popular"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Cyprus's rich history and Mediterranean charm through its diverse cities: Nicosia's unique division, Limassol's cosmopolitan vibe, and Paphos's ancient wonders."
    }
  },

  // England
  "GB-ENG": {
    name: "England",
    code: "GB-ENG",
    cities: [
      {
        name: "London",
        ready: false,
        image: "",
        description: "A global metropolis blending centuries of history with cutting-edge culture and innovation.",
        stripeLink: "YOUR_LONDON_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "4-5 days",
          language: "English",
          transport: "Extensive Underground (Tube), buses, trains",
          currency: "British Pound (£)",
          bestTime: "May to September",
          travelTips: [
            "Get Oyster card for transport",
            "Book major attractions online",
            "Many museums are free",
            "Stand on right on escalators",
            "Use contactless payments",
            "Book theatre tickets ahead",
            "Download Citymapper app",
            "Use night buses for late travel"
          ]
        }
      },
      {
        name: "Manchester",
        ready: false,
        image: "",
        description: "A vibrant city known for its industrial heritage, music scene, and football culture.",
        stripeLink: "YOUR_MANCHESTER_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "2-3 days",
          language: "English",
          transport: "Trams, buses, and trains",
          currency: "British Pound (£)",
          bestTime: "June to September",
          travelTips: [
            "Get day tram pass for travel",
            "Book football matches early",
            "Use free city bus service",
            "Visit Northern Quarter for food",
            "Check gig listings ahead",
            "Sunday trading hours limited",
            "Book restaurants in advance",
            "Use contactless payments"
          ]
        }
      },
      {
        name: "Liverpool",
        ready: false,
        image: "",
        description: "UNESCO maritime city famous for The Beatles, football, and cultural renaissance.",
        stripeLink: "YOUR_LIVERPOOL_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "2-3 days",
          language: "English",
          transport: "Buses and trains, walkable center",
          currency: "British Pound (£)",
          bestTime: "May to September",
          travelTips: [
            "Use Saveaway ticket for transport",
            "Book Beatles tours ahead",
            "Visit museums on quiet days",
            "Albert Dock best in morning",
            "Check football schedules",
            "Book evening tours early",
            "Use contactless payments",
            "Sunday hours more limited"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Discover England's diversity from London's global appeal to Manchester's industrial charm and Liverpool's musical heritage."
    }
  },

  // France
  FR: {
    name: "France",
    code: "FR",
    cities: [
      {
        name: "Paris",
        ready: false,
        image: "",
        description: "The City of Light, home to iconic landmarks, world-class art, and incomparable culture.",
        stripeLink: "YOUR_PARIS_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "4-5 days",
          language: "French, English in tourist areas",
          transport: "Metro, RER, buses",
          currency: "Euro (€)",
          bestTime: "April to October",
          travelTips: [
            "Buy carnet of metro tickets",
            "Book museums online",
            "Learn basic French phrases",
            "Restaurants close 2-7pm",
            "Many places closed Mondays",
            "Validate train tickets",
            "Watch for pickpockets",
            "Dinner reservations essential"
          ]
        }
      },
      {
        name: "Nice",
        ready: false,
        image: "",
        description: "Mediterranean jewel known for its Promenade des Anglais, art museums, and vibrant old town.",
        stripeLink: "YOUR_NICE_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "3-4 days",
          language: "French, English in tourist areas",
          transport: "Trams and buses",
          currency: "Euro (€)",
          bestTime: "May to October",
          travelTips: [
            "Get multi-day tram pass",
            "Visit markets in morning",
            "Book beach clubs ahead",
            "Carry water bottle",
            "Learn basic French",
            "Reserve restaurants early",
            "Watch for bike lanes",
            "Many shops close Sunday"
          ]
        }
      },
      {
        name: "Lyon",
        ready: false,
        image: "",
        description: "France's gastronomic capital with a rich history and stunning architecture.",
        stripeLink: "YOUR_LYON_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "French, limited English",
          transport: "Metro, trams, funiculars",
          currency: "Euro (€)",
          bestTime: "March to October",
          travelTips: [
            "Get Lyon City Card",
            "Book bouchons in advance",
            "Use funiculars for hills",
            "Sunday morning markets",
            "Learn food vocabulary",
            "Validate transport tickets",
            "Many museums free first Sunday",
            "Traboules close at night"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience France's diverse charm from Paris's iconic beauty to Nice's Mediterranean allure and Lyon's culinary excellence."
    }
  },

  // Germany
  DE: {
    name: "Germany",
    code: "DE",
    cities: [
      {
        name: "Berlin",
        ready: false,
        image: "",
        description: "A dynamic capital where history meets modernity, known for its art scene, nightlife, and compelling history.",
        stripeLink: "YOUR_BERLIN_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "4-5 days",
          language: "German, English widely spoken",
          transport: "U-Bahn, S-Bahn, buses, trams",
          currency: "Euro (€)",
          bestTime: "May to September",
          travelTips: [
            "Get WelcomeCard for transport",
            "Most shops closed Sundays",
            "Book popular restaurants ahead",
            "Validate transport tickets",
            "Cash preferred in many places",
            "Download BVG app",
            "Bike rentals widely available",
            "Museums free on first Sunday"
          ]
        }
      },
      {
        name: "Munich",
        ready: false,
        image: "",
        description: "Bavaria's capital combining traditional charm with modern sophistication.",
        stripeLink: "YOUR_MUNICH_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "3-4 days",
          language: "German, English in tourist areas",
          transport: "U-Bahn, S-Bahn, trams",
          currency: "Euro (€)",
          bestTime: "April to October, September for Oktoberfest",
          travelTips: [
            "Get IsarCard for transport",
            "Book biergartens in summer",
            "Shops closed on Sundays",
            "Reserve for Oktoberfest early",
            "Carry cash for smaller places",
            "Download MVV app",
            "Use bike lanes properly",
            "Validate all transit tickets"
          ]
        }
      },
      {
        name: "Hamburg",
        ready: false,
        image: "",
        description: "Maritime city known for its harbor, cultural venues, and distinctive architecture.",
        stripeLink: "YOUR_HAMBURG_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "German, English in tourist areas",
          transport: "U-Bahn, S-Bahn, ferries",
          currency: "Euro (€)",
          bestTime: "May to September",
          travelTips: [
            "Get Hamburg Card",
            "Use harbor ferries",
            "Book fish market visits",
            "Sunday shopping limited",
            "Carry rain gear",
            "Download HVV app",
            "Harbor tours morning best",
            "Cash often preferred"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Germany's diversity from Berlin's energy to Munich's traditions and Hamburg's maritime charm."
    }
  },

  // Greece
  GR: {
    name: "Greece",
    code: "GR",
    cities: [
      {
        name: "Athens",
        ready: false,
        image: "",
        description: "Ancient civilization meets modern metropolis in Greece's historic capital.",
        stripeLink: "YOUR_ATHENS_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "3-4 days",
          language: "Greek, English in tourist areas",
          transport: "Metro, buses, trams",
          currency: "Euro (€)",
          bestTime: "March to May or September to November",
          travelTips: [
            "Get transport day passes",
            "Visit Acropolis early morning",
            "Carry water in summer",
            "Book guided tours ahead",
            "Watch for strike days",
            "Use metro from airport",
            "Haggle at flea markets",
            "Avoid noon heat in summer"
          ]
        }
      },
      {
        name: "Santorini",
        ready: false,
        image: "",
        description: "Iconic island known for white-washed buildings, sunsets, and volcanic beaches.",
        stripeLink: "YOUR_SANTORINI_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "3-4 days",
          language: "Greek, English widely spoken",
          transport: "Buses, taxis, ATVs",
          currency: "Euro (€)",
          bestTime: "April to October",
          travelTips: [
            "Book accommodations early",
            "Reserve sunset restaurants",
            "Use local buses",
            "Wear comfortable shoes",
            "Book ferry tickets ahead",
            "Carry cash for buses",
            "Download offline maps",
            "Schedule airport transfers"
          ]
        }
      },
      {
        name: "Mykonos",
        ready: false,
        image: "",
        description: "Cosmopolitan island famous for its beaches, nightlife, and picturesque architecture.",
        stripeLink: "YOUR_MYKONOS_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "3-4 days",
          language: "Greek, English widely spoken",
          transport: "Buses, taxis, water taxis",
          currency: "Euro (€)",
          bestTime: "May to October",
          travelTips: [
            "Book beach clubs ahead",
            "Reserve popular restaurants",
            "Use water taxis for beaches",
            "Carry cash for buses",
            "Book accommodation early",
            "Check ferry schedules",
            "Night buses available",
            "Download offline maps"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Discover Greece's treasures from Athens' ancient wonders to Santorini's dramatic views and Mykonos' vibrant atmosphere."
    }
  },

  // Iceland
  IS: {
    name: "Iceland",
    code: "IS",
    cities: [
      {
        name: "Reykjavik",
        ready: false,
        image: "",
        description: "World's northernmost capital, blending colorful architecture with geothermal wonders.",
        stripeLink: "YOUR_REYKJAVIK_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +4 hours",
          duration: "3-4 days",
          language: "Icelandic, English widely spoken",
          transport: "Buses, walking in center",
          currency: "Icelandic Króna (ISK)",
          bestTime: "June to August for midnight sun, September to March for Northern Lights",
          travelTips: [
            "Book tours in advance",
            "Use credit cards everywhere",
            "Pack weather-appropriate gear",
            "Download weather app",
            "Book swimming pools visits",
            "Use Straeto bus app",
            "Try local swimming pools",
            "Check road conditions"
          ]
        }
      },
      {
        name: "Akureyri",
        ready: false,
        image: "",
        description: "Iceland's northern capital, gateway to natural wonders and winter sports.",
        stripeLink: "YOUR_AKUREYRI_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +4 hours",
          duration: "2-3 days",
          language: "Icelandic, English widely spoken",
          transport: "Local buses, rental cars",
          currency: "Icelandic Króna (ISK)",
          bestTime: "June to August, or winter for skiing",
          travelTips: [
            "Check flight schedules",
            "Book car rental ahead",
            "Pack for all weather",
            "Download weather apps",
            "Carry swimming gear",
            "Check road conditions",
            "Book tours in advance",
            "Credit cards accepted"
          ]
        }
      },
      {
        name: "Vik",
        ready: false,
        image: "",
        description: "Picturesque coastal village surrounded by black sand beaches and dramatic landscapes.",
        stripeLink: "YOUR_VIK_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +4 hours",
          duration: "2-3 days",
          language: "Icelandic, English widely spoken",
          transport: "Rental cars, tours",
          currency: "Icelandic Króna (ISK)",
          bestTime: "May to September",
          travelTips: [
            "Book accommodation early",
            "Rent car in Reykjavik",
            "Check weather forecasts",
            "Download offline maps",
            "Carry waterproof gear",
            "Fill gas when possible",
            "Book tours ahead",
            "Keep emergency numbers"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Iceland's magic from Reykjavik's charm to Akureyri's northern wonders and Vik's dramatic landscapes."
    }
  },

  // Indonesia
  ID: {
    name: "Indonesia",
    code: "ID",
    cities: [
      {
        name: "Bali",
        ready: false,
        image: "",
        description: "Island of the Gods, known for beaches, temples, rice terraces, and vibrant culture.",
        stripeLink: "YOUR_BALI_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +12 hours",
          duration: "5-7 days",
          language: "Indonesian, English in tourist areas",
          transport: "Scooters, taxis, private drivers",
          currency: "Indonesian Rupiah (IDR)",
          bestTime: "April to October (dry season)",
          travelTips: [
            "Download Grab app",
            "Book private drivers ahead",
            "Carry cash for local shops",
            "Respect temple dress codes",
            "Negotiate taxi prices",
            "Use reliable ATMs",
            "Get local SIM card",
            "Book villas in advance"
          ]
        }
      },
      {
        name: "Jakarta",
        ready: false,
        image: "",
        description: "Indonesia's massive capital, a melting pot of cultures and commerce.",
        stripeLink: "YOUR_JAKARTA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +11 hours",
          duration: "2-3 days",
          language: "Indonesian, some English",
          transport: "MRT, buses, taxis",
          currency: "Indonesian Rupiah (IDR)",
          bestTime: "May to September",
          travelTips: [
            "Use Blue Bird taxis",
            "Download Grab app",
            "Avoid rush hours",
            "Use official money changers",
            "Visit malls for cooling",
            "Get transport card",
            "Book airport transfer",
            "Carry small change"
          ]
        }
      },
      {
        name: "Yogyakarta",
        ready: false,
        image: "",
        description: "Cultural heart of Java, home to ancient temples and traditional arts.",
        stripeLink: "YOUR_YOGYAKARTA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +11 hours",
          duration: "3-4 days",
          language: "Indonesian, limited English",
          transport: "Rickshaws, taxis, scooters",
          currency: "Indonesian Rupiah (IDR)",
          bestTime: "May to September",
          travelTips: [
            "Book temple tours early",
            "Use metered taxis",
            "Haggle at markets",
            "Respect local customs",
            "Carry small bills",
            "Book trains in advance",
            "Try street food safely",
            "Get local SIM card"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Explore Indonesia's diversity from Bali's spiritual charm to Jakarta's urban energy and Yogyakarta's cultural heritage."
    }
  },

  // Ireland
  IE: {
    name: "Ireland",
    code: "IE",
    cities: [
      {
        name: "Dublin",
        ready: false,
        image: "",
        description: "Ireland's lively capital, where literary heritage meets modern culture and traditional pubs.",
        stripeLink: "YOUR_DUBLIN_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "3-4 days",
          language: "English",
          transport: "DART, buses, trams (Luas)",
          currency: "Euro (€)",
          bestTime: "May to September",
          travelTips: [
            "Get Leap Card for transport",
            "Book popular pubs ahead",
            "Many museums are free",
            "Sunday trading hours limited",
            "Download Dublin Bus app",
            "Carry rain gear always",
            "Book tours early morning",
            "Use official taxi ranks"
          ]
        }
      },
      {
        name: "Galway",
        ready: false,
        image: "",
        description: "Bohemian city known for its arts scene, traditional music, and stunning coastal location.",
        stripeLink: "YOUR_GALWAY_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "2-3 days",
          language: "English, some Irish",
          transport: "Buses, walking",
          currency: "Euro (€)",
          bestTime: "June to September",
          travelTips: [
            "Book accommodation early",
            "Check festival dates",
            "Reserve music sessions",
            "Use Bus Eireann for trips",
            "Carry rain protection",
            "Book restaurants ahead",
            "Download local bus app",
            "Cash for street performers"
          ]
        }
      },
      {
        name: "Cork",
        ready: false,
        image: "",
        description: "Ireland's second city, known for food culture, historic architecture, and friendly locals.",
        stripeLink: "YOUR_CORK_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "2-3 days",
          language: "English",
          transport: "Buses, walking",
          currency: "Euro (€)",
          bestTime: "April to October",
          travelTips: [
            "Use Leap Card for buses",
            "Visit English Market early",
            "Book food tours ahead",
            "Sunday hours limited",
            "Carry umbrella always",
            "Download bus app",
            "Reserve popular restaurants",
            "Check event calendars"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Ireland's charm from Dublin's vibrancy to Galway's artistic soul and Cork's culinary excellence."
    }
  },

  // Israel
  IL: {
    name: "Israel",
    code: "IL",
    cities: [
      {
        name: "Jerusalem",
        ready: false,
        image: "",
        description: "Sacred city to three religions, blending ancient history with modern life.",
        stripeLink: "YOUR_JERUSALEM_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "4-5 days",
          language: "Hebrew, Arabic, English widely spoken",
          transport: "Light rail, buses",
          currency: "Israeli Shekel (₪)",
          bestTime: "March to May and September to November",
          travelTips: [
            "Get Rav-Kav for transport",
            "Respect religious sites",
            "Most closes on Shabbat",
            "Dress modestly for sites",
            "Book tours in advance",
            "Carry water always",
            "Download transport app",
            "Early morning for sites"
          ]
        }
      },
      {
        name: "Tel Aviv",
        ready: false,
        image: "",
        description: "Modern Mediterranean metropolis known for beaches, nightlife, and startup culture.",
        stripeLink: "YOUR_TELAVIV_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "3-4 days",
          language: "Hebrew, English widely spoken",
          transport: "Buses, bikes, scooters",
          currency: "Israeli Shekel (₪)",
          bestTime: "April to November",
          travelTips: [
            "Use Tel-O-Fun bikes",
            "Friday afternoon closures",
            "Book restaurants ahead",
            "Download Moovit app",
            "Beach gear essential",
            "Use sherut shared taxis",
            "Get local SIM card",
            "Early morning beaches"
          ]
        }
      },
      {
        name: "Haifa",
        ready: false,
        image: "",
        description: "Port city on Mount Carmel, known for religious tolerance and Bahai Gardens.",
        stripeLink: "YOUR_HAIFA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "2-3 days",
          language: "Hebrew, Arabic, English common",
          transport: "Buses, Carmelit (subway)",
          currency: "Israeli Shekel (₪)",
          bestTime: "April to October",
          travelTips: [
            "Use Rav-Kav card",
            "Book Gardens tour",
            "Check Carmelit times",
            "Download bus app",
            "Carry water bottle",
            "Weekend schedules differ",
            "Book German Colony dining",
            "Morning garden visits"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Discover Israel's diversity from Jerusalem's spirituality to Tel Aviv's modernity and Haifa's coexistence."
    }
  },

  // Italy
  IT: {
    name: "Italy",
    code: "IT",
    cities: [
      {
        name: "Rome",
        ready: false,
        image: "",
        description: "The Eternal City, where ancient history meets modern life in a spectacular blend.",
        stripeLink: "YOUR_ROME_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "4-5 days",
          language: "Italian, English in tourist areas",
          transport: "Metro, buses, trams",
          currency: "Euro (€)",
          bestTime: "April to mid-June or September to October",
          travelTips: [
            "Book attractions online",
            "Validate transport tickets",
            "Carry water bottle",
            "Respect dress codes",
            "Early morning visits",
            "Use official taxis",
            "Learn basic Italian",
            "Watch for pickpockets"
          ]
        }
      },
      {
        name: "Venice",
        ready: true,
        image: "/images/cities/Venice.jpg",
        description: "Unique canal city known for its art, architecture, and romantic atmosphere.",
        stripeLink: "https://buy.stripe.com/8wM8AyeWrbSpaoE5kp",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "Italian, English in tourist areas",
          transport: "Vaporetto (water buses), walking",
          currency: "Euro (€)",
          bestTime: "April to May or September to October",
          travelTips: [
            "Get vaporetto pass",
            "Book accommodation early",
            "Download offline maps",
            "Avoid flooding seasons",
            "Early morning walks",
            "Use official water taxis",
            "Carry cash for small shops",
            "Book restaurants ahead"
          ]
        }
      },
      {
        name: "Florence",
        ready: false,
        image: "",
        description: "Birthplace of the Renaissance, known for art, architecture, and Tuscan cuisine.",
        stripeLink: "YOUR_FLORENCE_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "3-4 days",
          language: "Italian, English in tourist areas",
          transport: "Buses, walking",
          currency: "Euro (€)",
          bestTime: "March to May or September to October",
          travelTips: [
            "Book museums online",
            "Validate bus tickets",
            "Early morning visits",
            "Reserve restaurants",
            "Download bus app",
            "Carry water bottle",
            "Watch for ZTL zones",
            "Learn basic Italian"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Italy's treasures from Rome's ancient wonders to Venice's romantic canals and Florence's artistic heritage."
    }
  },

  // Japan
  JP: {
    name: "Japan",
    code: "JP",
    cities: [
      {
        name: "Tokyo",
        ready: false,
        image: "",
        description: "Ultra-modern metropolis blending cutting-edge technology with traditional culture.",
        stripeLink: "YOUR_TOKYO_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +13 hours",
          duration: "4-5 days",
          language: "Japanese, limited English",
          transport: "Metro, JR trains, buses",
          currency: "Japanese Yen (¥)",
          bestTime: "March-May or October-November",
          travelTips: [
            "Get IC card for transport",
            "Download metro app",
            "Book restaurants ahead",
            "Use convenience stores",
            "Queue on correct side",
            "Get pocket wifi",
            "Learn basic phrases",
            "Check last train times"
          ]
        }
      },
      {
        name: "Kyoto",
        ready: false,
        image: "",
        description: "Japan's cultural heart, famous for temples, gardens, and traditional arts.",
        stripeLink: "YOUR_KYOTO_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +13 hours",
          duration: "3-4 days",
          language: "Japanese, some English",
          transport: "Buses, trains, bikes",
          currency: "Japanese Yen (¥)",
          bestTime: "March-May or October-November",
          travelTips: [
            "Get bus day pass",
            "Rent bicycle",
            "Book temples early",
            "Download bus app",
            "Carry cash always",
            "Check temple hours",
            "Learn basic greetings",
            "Reserve tea ceremonies"
          ]
        }
      },
      {
        name: "Osaka",
        ready: false,
        image: "",
        description: "Japan's kitchen and entertainment capital, known for food and nightlife.",
        stripeLink: "YOUR_OSAKA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +13 hours",
          duration: "2-3 days",
          language: "Japanese, limited English",
          transport: "Metro, trains",
          currency: "Japanese Yen (¥)",
          bestTime: "March-May or October-November",
          travelTips: [
            "Get IC card",
            "Try street food",
            "Book popular restaurants",
            "Download metro app",
            "Use convenience stores",
            "Check last trains",
            "Carry cash always",
            "Learn food phrases"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Discover Japan's contrasts from Tokyo's modernity to Kyoto's traditions and Osaka's culinary delights."
    }
  },
  // Mexico
  MX: {
    name: "Mexico",
    code: "MX",
    cities: [
      {
        name: "Mexico City",
        ready: false,
        image: "",
        description: "Vibrant capital blending colonial architecture, modern art, and ancient history.",
        stripeLink: "YOUR_MEXICOCITY_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC -1 hour",
          duration: "4-5 days",
          language: "Spanish, limited English",
          transport: "Metro, Metrobus, taxis",
          currency: "Mexican Peso (MXN)",
          bestTime: "March to May",
          travelTips: [
            "Use Metro card",
            "Book official taxis",
            "Download Uber app",
            "Carry small bills",
            "Watch altitude adjustment",
            "Use filtered water",
            "Learn basic Spanish",
            "Book museums online"
          ]
        }
      },
      {
        name: "Cancun",
        ready: false,
        image: "",
        description: "Caribbean paradise known for beaches, resorts, and nearby Mayan ruins.",
        stripeLink: "YOUR_CANCUN_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC -0 hours",
          duration: "4-5 days",
          language: "Spanish, English widely spoken",
          transport: "Buses, taxis, tours",
          currency: "Mexican Peso (MXN)",
          bestTime: "December to April",
          travelTips: [
            "Book tours early",
            "Use official taxis",
            "Get bus card",
            "Carry sunscreen",
            "Download transport app",
            "Exchange money safely",
            "Check weather updates",
            "Use bottled water"
          ]
        }
      },
      {
        name: "Tulum",
        ready: false,
        image: "",
        description: "Eco-chic destination with ancient ruins, cenotes, and pristine beaches.",
        stripeLink: "YOUR_TULUM_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC -0 hours",
          duration: "3-4 days",
          language: "Spanish, English common",
          transport: "Taxis, bikes, colectivos",
          currency: "Mexican Peso (MXN)",
          bestTime: "November to March",
          travelTips: [
            "Rent bikes",
            "Book cenote tours",
            "Carry cash",
            "Visit ruins early",
            "Reserve restaurants",
            "Use eco-friendly products",
            "Download offline maps",
            "Check tide schedules"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Mexico's diversity from Mexico City's culture to Cancun's beaches and Tulum's ecological wonders."
    }
  },

  // Morocco
  MA: {
    name: "Morocco",
    code: "MA",
    cities: [
      {
        name: "Marrakech",
        ready: false,
        image: "",
        description: "Red City known for its medina, souks, and vibrant cultural scene.",
        stripeLink: "YOUR_MARRAKECH_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "3-4 days",
          language: "Arabic, French, limited English",
          transport: "Taxis, walking",
          currency: "Moroccan Dirham (MAD)",
          bestTime: "March to May or September to November",
          travelTips: [
            "Learn basic haggling",
            "Use official guides",
            "Download offline maps",
            "Dress respectfully",
            "Carry small bills",
            "Book riads early",
            "Use bottled water",
            "Arrange airport transfer"
          ]
        }
      },
      {
        name: "Fes",
        ready: false,
        image: "",
        description: "Medieval city with the world's largest car-free urban area and traditional crafts.",
        stripeLink: "YOUR_FES_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "2-3 days",
          language: "Arabic, French, limited English",
          transport: "Walking, taxis",
          currency: "Moroccan Dirham (MAD)",
          bestTime: "March to May or September to November",
          travelTips: [
            "Hire official guide",
            "Book riad ahead",
            "Carry small change",
            "Download offline maps",
            "Use bottled water",
            "Dress conservatively",
            "Learn basic phrases",
            "Avoid night walks"
          ]
        }
      },
      {
        name: "Casablanca",
        ready: false,
        image: "",
        description: "Modern commercial center with Art Deco architecture and impressive mosque.",
        stripeLink: "YOUR_CASABLANCA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "2-3 days",
          language: "Arabic, French, some English",
          transport: "Trams, taxis",
          currency: "Moroccan Dirham (MAD)",
          bestTime: "March to May or September to November",
          travelTips: [
            "Use petit taxis",
            "Book mosque visits",
            "Download tram app",
            "Carry small bills",
            "Use official taxis",
            "Learn basic French",
            "Dress appropriately",
            "Exchange money safely"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Explore Morocco's magic from Marrakech's bazaars to Fes's medieval charm and Casablanca's modern energy."
    }
  },

  // Netherlands
  NL: {
    name: "Netherlands",
    code: "NL",
    cities: [
      {
        name: "Amsterdam",
        ready: false,
        image: "",
        description: "Canal-ringed city known for its artistic heritage, cycling culture, and historic houses.",
        stripeLink: "YOUR_AMSTERDAM_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "3-4 days",
          language: "Dutch, English widely spoken",
          transport: "Trams, metros, bikes",
          currency: "Euro (€)",
          bestTime: "April to May or September",
          travelTips: [
            "Get transport card",
            "Book museums online",
            "Rent bikes carefully",
            "Watch for cyclists",
            "Download transport app",
            "Reserve restaurants",
            "Buy advance tickets",
            "Use bike lights night"
          ]
        }
      },
      {
        name: "Rotterdam",
        ready: false,
        image: "",
        description: "Modern port city known for bold architecture and innovative design.",
        stripeLink: "YOUR_ROTTERDAM_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "Dutch, English widely spoken",
          transport: "Metro, trams, water taxi",
          currency: "Euro (€)",
          bestTime: "May to September",
          travelTips: [
            "Get RET day tickets",
            "Use water taxis",
            "Book market hall visits",
            "Download transport app",
            "Check port tours",
            "Reserve restaurants",
            "Use bike sharing",
            "Watch weather forecast"
          ]
        }
      },
      {
        name: "The Hague",
        ready: false,
        image: "",
        description: "Political capital combining stately architecture with seaside charm.",
        stripeLink: "YOUR_THEHAGUE_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "Dutch, English widely spoken",
          transport: "Trams, buses, bikes",
          currency: "Euro (€)",
          bestTime: "April to October",
          travelTips: [
            "Get HTM day pass",
            "Book museums ahead",
            "Use bike sharing",
            "Download transport app",
            "Check beach weather",
            "Reserve restaurants",
            "Visit markets early",
            "Use official taxis"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Discover Dutch diversity from Amsterdam's historic charm to Rotterdam's modernity and The Hague's elegance."
    }
  },

  // Poland
  PL: {
    name: "Poland",
    code: "PL",
    cities: [
      {
        name: "Warsaw",
        ready: false,
        image: "",
        description: "Phoenix city rebuilt from WWII, blending history with modern vitality.",
        stripeLink: "YOUR_WARSAW_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "3-4 days",
          language: "Polish, English in tourist areas",
          transport: "Metro, trams, buses",
          currency: "Polish Złoty (PLN)",
          bestTime: "May to September",
          travelTips: [
            "Get transport card",
            "Download taxi app",
            "Book museums online",
            "Use official guides",
            "Exchange money safely",
            "Download transit app",
            "Sunday shopping limited",
            "Carry small change"
          ]
        }
      },
      {
        name: "Krakow",
        ready: false,
        image: "",
        description: "Medieval gem with Europe's largest market square and rich Jewish heritage.",
        stripeLink: "YOUR_KRAKOW_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "3-4 days",
          language: "Polish, English in tourist areas",
          transport: "Trams, buses",
          currency: "Polish Złoty (PLN)",
          bestTime: "April to October",
          travelTips: [
            "Get transport card",
            "Book salt mines early",
            "Download tram app",
            "Use official tours",
            "Carry small bills",
            "Sunday shopping closed",
            "Reserve restaurants",
            "Watch for pickpockets"
          ]
        }
      },
      {
        name: "Gdansk",
        ready: false,
        image: "",
        description: "Historic Baltic port city known for amber trade and maritime heritage.",
        stripeLink: "YOUR_GDANSK_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "Polish, English in tourist areas",
          transport: "Trams, buses, trains",
          currency: "Polish Złoty (PLN)",
          bestTime: "May to September",
          travelTips: [
            "Get transport pass",
            "Check amber quality",
            "Book beach trips",
            "Download transit app",
            "Use official taxis",
            "Reserve restaurants",
            "Watch weather forecast",
            "Exchange money safely"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Poland's contrasts from Warsaw's resilience to Krakow's medieval charm and Gdansk's maritime heritage."
    }
  },
   // Portugal
   PT: {
    name: "Portugal",
    code: "PT",
    cities: [
      {
        name: "Lisbon",
        ready: false,
        image: "",
        description: "Hilly coastal capital famous for historic trams, fado music, and pastéis de nata.",
        stripeLink: "YOUR_LISBON_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "3-4 days",
          language: "Portuguese, English widely spoken",
          transport: "Metro, trams, buses",
          currency: "Euro (€)",
          bestTime: "March to May or September to October",
          travelTips: [
            "Get Viva Viagem card",
            "Book fado shows early",
            "Wear comfortable shoes",
            "Watch for pickpockets",
            "Download Metro app",
            "Early morning visits",
            "Reserve restaurants",
            "Use official taxis"
          ]
        }
      },
      {
        name: "Porto",
        ready: false,
        image: "",
        description: "Historic riverside city known for port wine, bridges, and medieval district.",
        stripeLink: "YOUR_PORTO_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "2-3 days",
          language: "Portuguese, English common",
          transport: "Metro, buses, trams",
          currency: "Euro (€)",
          bestTime: "May to September",
          travelTips: [
            "Get Andante card",
            "Book wine tours",
            "Download Metro app",
            "Check bridge levels",
            "Reserve restaurants",
            "Early market visits",
            "Use sunscreen",
            "Carry small change"
          ]
        }
      },
      {
        name: "Algarve",
        ready: false,
        image: "",
        description: "Southern coastal region known for beaches, golf, and dramatic cliffs.",
        stripeLink: "YOUR_ALGARVE_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "3-4 days",
          language: "Portuguese, English widely spoken",
          transport: "Buses, rental cars",
          currency: "Euro (€)",
          bestTime: "June to September",
          travelTips: [
            "Book car advance",
            "Reserve beach clubs",
            "Check tide times",
            "Download offline maps",
            "Carry water bottle",
            "Book boat tours early",
            "Use sunscreen",
            "Reserve restaurants"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Portugal's charm from Lisbon's hills to Porto's wine culture and Algarve's stunning coastline."
    }
  },

  // Romania
  RO: {
    name: "Romania",
    code: "RO",
    cities: [
      {
        name: "Bucharest",
        ready: false,
        image: "",
        description: "Dynamic capital blending communist-era architecture with Belle Époque buildings.",
        stripeLink: "YOUR_BUCHAREST_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "2-3 days",
          language: "Romanian, English in tourist areas",
          transport: "Metro, buses, trams",
          currency: "Romanian Leu (RON)",
          bestTime: "April to June or September to October",
          travelTips: [
            "Get Metro card",
            "Use official taxis",
            "Download transport app",
            "Exchange money safely",
            "Book tours advance",
            "Carry small bills",
            "Watch for scams",
            "Use card in hotels"
          ]
        }
      },
      {
        name: "Brasov",
        ready: false,
        image: "",
        description: "Medieval Transylvanian city surrounded by the Carpathian Mountains.",
        stripeLink: "YOUR_BRASOV_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "2-3 days",
          language: "Romanian, some English",
          transport: "Buses, taxis",
          currency: "Romanian Leu (RON)",
          bestTime: "June to September",
          travelTips: [
            "Use bus card",
            "Book castle tours",
            "Download offline maps",
            "Carry cash backup",
            "Check snow reports",
            "Reserve restaurants",
            "Use official guides",
            "Early morning visits"
          ]
        }
      },
      {
        name: "Sibiu",
        ready: false,
        image: "",
        description: "Cultural capital known for its Germanic architecture and surrounding landscapes.",
        stripeLink: "YOUR_SIBIU_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "2-3 days",
          language: "Romanian, limited English",
          transport: "Buses, walking",
          currency: "Romanian Leu (RON)",
          bestTime: "May to September",
          travelTips: [
            "Get bus tickets",
            "Download city app",
            "Book guided walks",
            "Carry small bills",
            "Use official taxis",
            "Reserve restaurants",
            "Check event calendar",
            "Early morning photos"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Discover Romania's diversity from Bucharest's energy to Brasov's medieval charm and Sibiu's cultural heritage."
    }
  },

  // Scotland
  "GB-SCT": {
    name: "Scotland",
    code: "GB-SCT",
    cities: [
      {
        name: "Edinburgh",
        ready: false,
        image: "",
        description: "Historic capital combining medieval Old Town with elegant Georgian New Town.",
        stripeLink: "YOUR_EDINBURGH_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "3-4 days",
          language: "English",
          transport: "Buses, trams",
          currency: "British Pound (£)",
          bestTime: "June to September",
          travelTips: [
            "Get transport DAYticket",
            "Book castle visits",
            "Download bus app",
            "Carry rain gear",
            "Book festivals early",
            "Use contactless pay",
            "Reserve restaurants",
            "Early morning tours"
          ]
        }
      },
      {
        name: "Glasgow",
        ready: false,
        image: "",
        description: "Vibrant city known for culture, architecture, and music scene.",
        stripeLink: "YOUR_GLASGOW_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "2-3 days",
          language: "English",
          transport: "Subway, buses",
          currency: "British Pound (£)",
          bestTime: "May to September",
          travelTips: [
            "Get Subway SmartCard",
            "Book music venues",
            "Download transport app",
            "Carry umbrella",
            "Use contactless pay",
            "Check gig listings",
            "Reserve restaurants",
            "Sunday trading hours"
          ]
        }
      },
      {
        name: "Isle of Skye",
        ready: false,
        image: "",
        description: "Dramatic island landscapes with historic castles and rugged mountains.",
        stripeLink: "YOUR_SKYE_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +5 hours",
          duration: "2-3 days",
          language: "English, Gaelic",
          transport: "Car rental, tours",
          currency: "British Pound (£)",
          bestTime: "May to September",
          travelTips: [
            "Book accommodation early",
            "Rent car advance",
            "Check weather often",
            "Download offline maps",
            "Carry rain gear",
            "Book tours ahead",
            "Fill fuel when seen",
            "Reserve restaurants"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Scotland's magic from Edinburgh's history to Glasgow's culture and Skye's natural beauty."
    }
  },

  // South Africa
  ZA: {
    name: "South Africa",
    code: "ZA",
    cities: [
      {
        name: "Cape Town",
        ready: false,
        image: "",
        description: "Stunning coastal city beneath Table Mountain, known for beaches and wine regions.",
        stripeLink: "YOUR_CAPETOWN_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "4-5 days",
          language: "English, Afrikaans, Xhosa",
          transport: "Uber, MyCiTi buses",
          currency: "South African Rand (ZAR)",
          bestTime: "October to April",
          travelTips: [
            "Book Table Mountain early",
            "Use reliable transport",
            "Download Uber app",
            "Check wind forecasts",
            "Book wine tours",
            "Carry sunscreen",
            "Reserve restaurants",
            "Use official guides"
          ]
        }
      },
      {
        name: "Johannesburg",
        ready: false,
        image: "",
        description: "Vibrant economic hub with rich history and cultural attractions.",
        stripeLink: "YOUR_JOHANNESBURG_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "English widely spoken",
          transport: "Uber, Gautrain",
          currency: "South African Rand (ZAR)",
          bestTime: "March to May or September to November",
          travelTips: [
            "Use Gautrain card",
            "Book guided tours",
            "Download Uber app",
            "Use hotel transport",
            "Carry small cash",
            "Stay alert at night",
            "Reserve restaurants",
            "Book attractions"
          ]
        }
      },
      {
        name: "Kruger Park",
        ready: false,
        image: "",
        description: "Premier wildlife destination offering exceptional safari experiences.",
        stripeLink: "YOUR_KRUGER_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "3-4 days",
          language: "English widely spoken",
          transport: "Rental car, guided tours",
          currency: "South African Rand (ZAR)",
          bestTime: "May to September",
          travelTips: [
            "Book lodges early",
            "Get park permits",
            "Carry binoculars",
            "Download park app",
            "Check gate times",
            "Bring sun protection",
            "Book guided drives",
            "Follow park rules"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Explore South Africa's diversity from Cape Town's beauty to Johannesburg's energy and Kruger's wildlife."
    }
  },
 // Spain
 ES: {
    name: "Spain",
    code: "ES",
    cities: [
      {
        name: "Madrid",
        ready: false,
        image: "",
        description: "Elegant capital known for world-class art museums, grand plazas, and vibrant nightlife.",
        stripeLink: "YOUR_MADRID_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "3-4 days",
          language: "Spanish, limited English",
          transport: "Metro, buses",
          currency: "Euro (€)",
          bestTime: "March to May or September to October",
          travelTips: [
            "Get Metro tourist pass",
            "Book museums online",
            "Adjust to late schedule",
            "Download Metro app",
            "Watch for pickpockets",
            "Book flamenco shows",
            "Siesta hours observed",
            "Reserve restaurants"
          ]
        }
      },
      {
        name: "Barcelona",
        ready: false,
        image: "",
        description: "Mediterranean jewel famous for unique architecture, beaches, and Catalan culture.",
        stripeLink: "YOUR_BARCELONA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "3-4 days",
          language: "Spanish, Catalan, some English",
          transport: "Metro, buses",
          currency: "Euro (€)",
          bestTime: "April to June or September to October",
          travelTips: [
            "Book Sagrada Familia",
            "Get T-casual card",
            "Watch belongings",
            "Book Gaudi sites",
            "Download TMB app",
            "Early morning visits",
            "Reserve tapas tours",
            "Beach time planning"
          ]
        }
      },
      {
        name: "Seville",
        ready: false,
        image: "",
        description: "Andalusian capital with rich Moorish heritage, flamenco culture, and tapas scenes.",
        stripeLink: "YOUR_SEVILLE_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "Spanish, limited English",
          transport: "Buses, trams, bikes",
          currency: "Euro (€)",
          bestTime: "March to May or September to November",
          travelTips: [
            "Get transport card",
            "Book Alcazar early",
            "Avoid summer heat",
            "Download bus app",
            "Use bike sharing",
            "Reserve flamenco",
            "Early morning visits",
            "Take evening walks"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Discover Spain's diversity from Madrid's grandeur to Barcelona's creativity and Seville's passionate spirit."
    }
  },

  // Switzerland
  CH: {
    name: "Switzerland",
    code: "CH",
    cities: [
      {
        name: "Zurich",
        ready: false,
        image: "",
        description: "Banking capital with rich cultural scene, lakeside location, and excellent quality of life.",
        stripeLink: "YOUR_ZURICH_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "German, English widely spoken",
          transport: "Trams, trains, boats",
          currency: "Swiss Franc (CHF)",
          bestTime: "June to September",
          travelTips: [
            "Get ZurichCARD",
            "Download SBB app",
            "Book restaurants",
            "Use contactless pay",
            "Check lake cruises",
            "Validate tickets",
            "Sunday closures",
            "Early morning walks"
          ]
        }
      },
      {
        name: "Geneva",
        ready: false,
        image: "",
        description: "International diplomatic center on a beautiful lake with French influence.",
        stripeLink: "YOUR_GENEVA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "French, English widely spoken",
          transport: "Trams, buses, boats",
          currency: "Swiss Franc (CHF)",
          bestTime: "June to September",
          travelTips: [
            "Get transport pass",
            "Use free bikes",
            "Book lake tours",
            "Download TPG app",
            "Check event calendar",
            "Watch water jet times",
            "Reserve restaurants",
            "Sunday closures"
          ]
        }
      },
      {
        name: "Lucerne",
        ready: false,
        image: "",
        description: "Picture-perfect city with medieval architecture and mountain views.",
        stripeLink: "YOUR_LUCERNE_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +6 hours",
          duration: "2-3 days",
          language: "German, English common",
          transport: "Buses, boats, trains",
          currency: "Swiss Franc (CHF)",
          bestTime: "June to September",
          travelTips: [
            "Get Tell Pass",
            "Book mountain trips",
            "Check weather",
            "Download transport app",
            "Early morning photos",
            "Lake cruise timing",
            "Reserve restaurants",
            "Carry cash backup"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Switzerland's excellence from Zurich's modernity to Geneva's international flair and Lucerne's charm."
    }
  },

  // Thailand
  TH: {
    name: "Thailand",
    code: "TH",
    cities: [
      {
        name: "Bangkok",
        ready: false,
        image: "",
        description: "Vibrant capital where traditional meets modern, known for temples, street food, and nightlife.",
        stripeLink: "YOUR_BANGKOK_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +11 hours",
          duration: "3-4 days",
          language: "Thai, English in tourist areas",
          transport: "BTS Skytrain, MRT, boats",
          currency: "Thai Baht (฿)",
          bestTime: "November to March",
          travelTips: [
            "Get Rabbit Card",
            "Use metered taxis",
            "Download Grab app",
            "Respect temple dress",
            "Carry small bills",
            "Book river tours",
            "Watch for scams",
            "Early temple visits"
          ]
        }
      },
      {
        name: "Phuket",
        ready: false,
        image: "",
        description: "Tropical paradise known for beaches, islands, and water activities.",
        stripeLink: "YOUR_PHUKET_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +11 hours",
          duration: "4-5 days",
          language: "Thai, English in tourist areas",
          transport: "Taxis, tuk-tuks, rental bikes",
          currency: "Thai Baht (฿)",
          bestTime: "November to April",
          travelTips: [
            "Book island tours",
            "Use Grab app",
            "Check weather",
            "Negotiate prices",
            "Get local SIM",
            "Reserve beach clubs",
            "Carry sunscreen",
            "Book airport transfer"
          ]
        }
      },
      {
        name: "Chiang Mai",
        ready: false,
        image: "",
        description: "Cultural northern capital known for temples, crafts, and mountain scenery.",
        stripeLink: "YOUR_CHIANGMAI_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +11 hours",
          duration: "3-4 days",
          language: "Thai, some English",
          transport: "Songthaews, tuk-tuks",
          currency: "Thai Baht (฿)",
          bestTime: "November to February",
          travelTips: [
            "Book cooking classes",
            "Use Grab app",
            "Temple dress code",
            "Download offline maps",
            "Negotiate transport",
            "Check festival dates",
            "Early morning markets",
            "Reserve elephant parks"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Explore Thailand's variety from Bangkok's energy to Phuket's beaches and Chiang Mai's cultural richness."
    }
  },

  // Turkey
  TR: {
    name: "Turkey",
    code: "TR",
    cities: [
      {
        name: "Istanbul",
        ready: false,
        image: "",
        description: "Historic city straddling two continents, known for grand mosques, bazaars, and Bosphorus views.",
        stripeLink: "YOUR_ISTANBUL_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "4-5 days",
          language: "Turkish, English in tourist areas",
          transport: "Metro, trams, ferries",
          currency: "Turkish Lira (₺)",
          bestTime: "April to May or September to November",
          travelTips: [
            "Get IstanbulKart",
            "Book Hagia Sophia",
            "Download transport app",
            "Use ferries",
            "Dress modestly",
            "Learn basic phrases",
            "Bargain in bazaars",
            "Early mosque visits"
          ]
        }
      },
      {
        name: "Cappadocia",
        ready: false,
        image: "",
        description: "Unique landscape known for cave hotels, hot air balloons, and underground cities.",
        stripeLink: "YOUR_CAPPADOCIA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "3-4 days",
          language: "Turkish, limited English",
          transport: "Tours, rental cars",
          currency: "Turkish Lira (₺)",
          bestTime: "April to June or September to November",
          travelTips: [
            "Book balloon rides",
            "Reserve cave hotels",
            "Download offline maps",
            "Check weather",
            "Book tours ahead",
            "Carry water",
            "Comfortable shoes",
            "Early morning starts"
          ]
        }
      },
      {
        name: "Antalya",
        ready: false,
        image: "",
        description: "Mediterranean resort city with ancient ruins, beaches, and mountain backdrop.",
        stripeLink: "YOUR_ANTALYA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +7 hours",
          duration: "3-4 days",
          language: "Turkish, English in tourist areas",
          transport: "Trams, buses, dolmus",
          currency: "Turkish Lira (₺)",
          bestTime: "April to October",
          travelTips: [
            "Get transport card",
            "Book beach clubs",
            "Use dolmus system",
            "Download transit app",
            "Check boat tours",
            "Carry sunscreen",
            "Reserve restaurants",
            "Early ruins visits"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience Turkey's wonders from Istanbul's history to Cappadocia's landscapes and Antalya's coastal charm."
    }
  },

  // United Arab Emirates
  AE: {
    name: "United Arab Emirates",
    code: "AE",
    cities: [
      {
        name: "Dubai",
        ready: false,
        image: "",
        description: "Futuristic city known for luxury shopping, ultramodern architecture, and desert adventures.",
        stripeLink: "YOUR_DUBAI_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +8 hours",
          duration: "4-5 days",
          language: "Arabic, English widely spoken",
          transport: "Metro, taxis, buses",
          currency: "UAE Dirham (AED)",
          bestTime: "November to March",
          travelTips: [
            "Get Nol Card",
            "Book Burj Khalifa",
            "Download RTA app",
            "Dress modestly",
            "Use Metro link",
            "Book desert tours",
            "Reserve brunches",
            "Early morning visits"
          ]
        }
      },
      {
        name: "Abu Dhabi",
        ready: false,
        image: "",
        description: "UAE capital known for grand mosque, cultural sites, and luxury experiences.",
        stripeLink: "YOUR_ABUDHABI_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +8 hours",
          duration: "2-3 days",
          language: "Arabic, English widely spoken",
          transport: "Taxis, buses",
          currency: "UAE Dirham (AED)",
          bestTime: "November to March",
          travelTips: [
            "Get bus card",
            "Book mosque visits",
            "Download bus app",
            "Dress conservatively",
            "Use official taxis",
            "Reserve restaurants",
            "Early morning starts",
            "Check prayer times"
          ]
        }
      },
      {
        name: "Sharjah",
        ready: false,
        image: "",
        description: "Cultural emirate known for museums, heritage sites, and traditional markets.",
        stripeLink: "YOUR_SHARJAH_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +8 hours",
          duration: "1-2 days",
          language: "Arabic, English common",
          transport: "Buses, taxis",
          currency: "UAE Dirham (AED)",
          bestTime: "November to March",
          travelTips: [
            "Use bus service",
            "Book museum tours",
            "Respect local rules",
            "Friday timings differ",
            "Download maps",
            "Early market visits",
            "Conservative dress",
            "Check event calendar"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Discover UAE's contrasts from Dubai's modernity to Abu Dhabi's grandeur and Sharjah's cultural heritage."
    }
  },
  US: {
    name: "United States",
    code: "US",
    cities: [
      {
        name: "New York",
        ready: false,
        image: "",
        description: "Iconic global city known for culture, arts, shopping, and endless entertainment.",
        stripeLink: "YOUR_NEWYORK_STRIPE_LINK",
        travelInfo: {
          timeOffset: "Local Time",
          duration: "4-5 days",
          language: "English",
          transport: "Subway, buses",
          currency: "US Dollar ($)",
          bestTime: "April to June or September to November",
          travelTips: [
            "Get MetroCard",
            "Book attractions",
            "Download subway app",
            "Walk crosstown",
            "Check event listings",
            "Reserve restaurants",
            "Early museum visits",
            "Use card payments"
          ]
        }
      },
      {
        name: "Los Angeles",
        ready: false,
        image: "",
        description: "Entertainment capital known for Hollywood, beaches, and diverse culture.",
        stripeLink: "YOUR_LA_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC -3 hours",
          duration: "4-5 days",
          language: "English",
          transport: "Car rental, rideshares",
          currency: "US Dollar ($)",
          bestTime: "March to May or September to November",
          travelTips: [
            "Rent car early",
            "Download Uber app",
            "Check traffic apps",
            "Plan beach visits",
            "Book studio tours",
            "Reserve restaurants",
            "Early morning starts",
            "Carry parking cash"
          ]
        }
      },
      {
        name: "Las Vegas",
        ready: false,
        image: "",
        description: "Entertainment oasis known for casinos, shows, and desert adventures.",
        stripeLink: "YOUR_LASVEGAS_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC -3 hours",
          duration: "3-4 days",
          language: "English",
          transport: "Rideshares, monorail",
          currency: "US Dollar ($)",
          bestTime: "March to May or October to November",
          travelTips: [
            "Get monorail pass",
            "Book shows ahead",
            "Download resort apps",
            "Stay hydrated",
            "Check happy hours",
            "Reserve restaurants",
            "Join player clubs",
            "Use indoor routes"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Experience America's diversity from New York's energy to LA's glamour and Las Vegas's entertainment."
    }
  },

  // Vietnam
  VN: {
    name: "Vietnam",
    code: "VN",
    cities: [
      {
        name: "Hanoi",
        ready: false,
        image: "",
        description: "Historic capital blending colonial architecture with vibrant street life and culture.",
        stripeLink: "YOUR_HANOI_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +11 hours",
          duration: "3-4 days",
          language: "Vietnamese, limited English",
          transport: "Grab bikes, taxis",
          currency: "Vietnamese Dong (₫)",
          bestTime: "October to December",
          travelTips: [
            "Download Grab app",
            "Get local SIM",
            "Learn crossing rules",
            "Carry small bills",
            "Book tours ahead",
            "Early morning walks",
            "Try street food safely",
            "Bargain respectfully"
          ]
        }
      },
      {
        name: "Ho Chi Minh City",
        ready: false,
        image: "",
        description: "Dynamic southern hub known for war history, street food, and modern development.",
        stripeLink: "YOUR_HOCHIMINH_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +11 hours",
          duration: "3-4 days",
          language: "Vietnamese, some English",
          transport: "Grab bikes, buses",
          currency: "Vietnamese Dong (₫)",
          bestTime: "December to February",
          travelTips: [
            "Use Grab app",
            "Download maps offline",
            "Carry small notes",
            "Book Cu Chi early",
            "Try street food",
            "Watch bag safety",
            "Get local SIM",
            "Early market visits"
          ]
        }
      },
      {
        name: "Hoi An",
        ready: false,
        image: "",
        description: "Ancient trading port known for lanterns, tailors, and preserved architecture.",
        stripeLink: "YOUR_HOIAN_STRIPE_LINK",
        travelInfo: {
          timeOffset: "NYC +11 hours",
          duration: "2-3 days",
          language: "Vietnamese, basic English",
          transport: "Bicycles, taxis",
          currency: "Vietnamese Dong (₫)",
          bestTime: "February to July",
          travelTips: [
            "Rent bicycles",
            "Order clothes early",
            "Book cooking class",
            "Check lunar calendar",
            "Download offline maps",
            "Early photos best",
            "Carry umbrella",
            "Reserve restaurants"
          ]
        }
      }
    ],
    comboPackage: {
      ready: false,
      price: 35,
      stripeLink: "",
      description: "Discover Vietnam's charm from Hanoi's history to Ho Chi Minh's energy and Hoi An's ancient beauty."
    }
  }




} //This must be the last Bracket