import React, { useState, useEffect } from 'react';
import { Check, Clock, MapPin, Globe2, Calendar, X, CoinsIcon } from 'lucide-react';
import Flag from 'react-world-flags';
import { cityData } from '../../../data/cities';


const TravelInfo = ({ info }) => (
  <div className="">
    <h3 className="text-xl font-bold mb-4">Travel Information</h3>
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <div className="flex items-start gap-3 mb-4">
            <Clock className="w-6 h-6 flex-shrink-0 text-gray-500" />
            <div>
              <p className="font-medium">Local Time</p>
              <p className="text-gray-600">{info.timeOffset}</p>
            </div>
          </div>
          <div className="flex items-start gap-3 mb-4">
            <Calendar className="w-6 h-6 flex-shrink-0 text-gray-500" />
            <div>
              <p className="font-medium">Recommended Duration</p>
              <p className="text-gray-600">{info.duration}</p>
            </div>
          </div>
          <div className="flex items-start gap-3 mb-4">
            <Globe2 className="w-6 h-6 flex-shrink-0 text-gray-500" />
            <div>
              <p className="font-medium">Language Accessibility</p>
              <p className="text-gray-600">{info.language}</p>
            </div>
          </div>
        </div>
        
        <div>
          <div className="flex items-start gap-3 mb-4">
            <MapPin className="w-6 h-6 flex-shrink-0 text-gray-500" />
            <div>
              <p className="font-medium">Getting Around</p>
              <p className="text-gray-600">{info.transport}</p>
            </div>
          </div>
          <div className="flex items-start gap-3 mb-4">
            <CoinsIcon className="w-6 h-6 flex-shrink-0 text-gray-500" />
            <div>
              <p className="font-medium">Currency</p>
              <p className="text-gray-600">{info.currency}</p>
            </div>
          </div>
          <div className="flex items-start gap-3 mb-4">
            <Calendar className="w-6 h-6 flex-shrink-0 text-gray-500" />
            <div>
              <p className="font-medium">Best Time to Visit</p>
              <p className="text-gray-600">{info.bestTime}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 p-6 rounded-lg mt-6">
        <h4 className="font-bold mb-3">Travel Tips</h4>
        <ul className="list-disc list-inside space-y-2 text-gray-600">
          {info.travelTips.map((tip, index) => (
            <li key={index}>{tip}</li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

const CityCard = ({ city, onClick, isSelected }) => (
  <div 
    onClick={() => onClick(city)}
    className={`border rounded-lg bg-white cursor-pointer transition-all duration-300 hover:scale-105 hover:border-[#FF6B35] ${
      isSelected ? 'border-[#FF6B35] ring-2 ring-[#FF6B35]' : ''
    }`}
  >
    <div className="p-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-xl font-bold">{city.name}</h3>
        {city.ready ? (
          <span className="inline-flex items-center text-green-500 text-sm">
            <Check className="w-4 h-4 mr-1" />
            Ready
          </span>
        ) : (
          <span className="inline-flex items-center text-[#FF6B35] text-sm">
            <Clock className="w-4 h-4 mr-1" />
            Coming Soon
          </span>
        )}
      </div>
      
      {city.image && (
        <div className="mb-4">
          <img
            src={city.image}
            alt={city.name}
            className="w-full rounded-lg"
          />
        </div>
      )}
      
      <p className="text-gray-600 mb-4">{city.description}</p>
      
      {city.ready && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            window.location.href = city.stripeLink;
          }}
          className="w-full bg-[#FF6B35] hover:bg-[#FF8C35] text-white px-4 py-3 rounded-full transition-colors"
        >
          Buy Itinerary ($20)
        </button>
      )}
    </div>
  </div>
);

const CityModal = ({ country, onClose }) => {
    const [selectedCity, setSelectedCity] = useState(null);
    const countryData = cityData[country.code];
  
    console.log('Modal Data:', { country, countryData }); // Debug log
  
    useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }, []);
  
    if (!countryData) {
      console.error('No country data found for:', country);
      return null;
    }
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto">
        <div className="min-h-screen px-4 text-center">
          <div className="fixed inset-0" onClick={onClose} />
          
          <div className="inline-block w-full max-w-6xl my-8 text-left align-middle bg-white rounded-lg shadow-xl transform transition-all">
            <div className="relative p-6">
              <div className="flex justify-center items-center mb-8">
                <div className="flex items-center">
                  <Flag code={country.code} className="w-8 h-6 mr-3" />
                  <h2 className="text-2xl font-bold">{country.name}</h2>
                </div>
                </div>
  
              <button
                onClick={onClose}
                className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full"
              >
                <X className="w-6 h-6" />
              </button>
  
              <div className="grid md:grid-cols-2 gap-6">
                <div className="space-y-6">
                  <h3 className="text-xl font-semibold">Major Cities</h3>
                  {countryData.cities.map((city) => (
                    <div key={city.name}>
                      <CityCard
                        city={city}
                        onClick={setSelectedCity}
                        isSelected={selectedCity?.name === city.name}
                      />
                      {/* Show travel info directly under selected city on mobile */}
                      {selectedCity?.name === city.name && (
                        <div className="md:hidden mt-4">
                          <TravelInfo info={city.travelInfo} />
                        </div>
                      )}
                    </div>
                  ))}
                  
                  {/* Complete Package Card */}
                  <div className="border rounded-lg p-4 bg-white">
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="text-xl font-bold">Complete Package</h3>
                      <span className="inline-flex items-center text-[#FF6B35] text-sm">
                        <Clock className="w-4 h-4 mr-1" />
                        Coming Soon
                      </span>
                    </div>
                    <p className="text-gray-600">{countryData.comboPackage.description}</p>
                  </div>
                </div>
  
                {/* Desktop travel info */}
                <div className="hidden md:block">
                  {selectedCity ? (
                    <TravelInfo info={selectedCity.travelInfo} />
                  ) : (
                    <div className="h-full flex items-center justify-center text-gray-500">
                      Select a city to view travel information
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default CityModal;